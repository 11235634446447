<template>
  <div
    class="bg-grey relative lg:pt-60 pt-10 pb-10 overflow-hidden world-of-inspiration"
  >
    <div class="curve-line"></div>
    <div class="text-wrapper text-center m-auto py-6">
      <div
        v-html="$storyapi.richTextResolver.render(blok.title)"
        class="text-red-title text-4xl mb-6 font-mont"
      />
      <div
        v-html="$storyapi.richTextResolver.render(blok.text)"
        class="inspo-text md:text-lg text-base font-fgb"
      />
      <div v-if="blok && blok.InstagramLink" class="cursor-pointer">
        <div v-if="blok.InstagramLink.length">
          <a
            aria-label="Instagram link"
            :href="blok.InstagramLink[0].link.url"
            :target="blok.InstagramLink[0].link.target"
            >{{ blok.InstagramLink[0].link_text }}</a
          >
        </div>
      </div>
    </div>
    <div class="container inspiration-slider py-10">
      <VueSlickCarousel
        v-if="instafeed"
        v-bind="slickOptions"
        ref="slickOptions"
        class="slider relative"
      >
        <template #prevArrow="">
          <div class="custom-arrow custom-arrow-prev">
            <Icon
              icon="mingcute:left-line"
              width="50"
              height="50"
              color="#CD1619"
            />
          </div>
        </template>
        <template #nextArrow="">
          <div class="custom-arrow-next">
            <Icon
              icon="mingcute:right-line"
              width="50"
              height="50"
              color="#CD1619"
            />
          </div>
        </template>
        <div v-for="(feed, index) in instafeed" :key="index">
          <a :href="feed.permalink" target="_blank" aria-label="feed link">
            <nuxt-img
              quality="90"
              format="webp"
              placeholder
              :src="feed.media_url"
              alt="link image"
            />
          </a>
        </div>
      </VueSlickCarousel>
      <VueSlickCarousel
        v-else
        v-bind="slickOptions"
        ref="slickOptions"
        class="slider"
      >
        <div v-for="blok in blok.blocks" :key="blok.id">
          <OurBrandsBlock :blok="blok" />
        </div>
      </VueSlickCarousel>
    </div>
    <div
      class="mx-auto w-fit"
      v-if="blok.button_link && blok.button_link.linktype == 'url'"
    >
      <div v-if="blok.button_link.url">
        <a :href="blok.button_link.url" target="_blank">
          <button v-if="blok.button" class="btn capitalize m-auto">
            {{ blok.button }}
          </button>
        </a>
      </div>
      <div v-else-if="blok.button_link && blok.button_link.linktype == 'story'">
        <nuxt-link :to="localePath('/' + blok.button_link.story.url)">
          <button v-if="blok.button" class="btn capitalize m-auto">
            {{ blok.button }}
          </button>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
import OurBrandsBlock from "../OurBrandsBlock.vue";
import { Icon } from "@iconify/vue2";
import VueSlickCarousel from "vue-slick-carousel";
import { ref } from "@nuxtjs/composition-api";
import axios from "axios";

export default {
  name: "WorldOfInspiration",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
  components: {
    OurBrandsBlock,
    VueSlickCarousel,
    Icon,
  },
  setup() {
    const instafeed = ref();

    return {
      instafeed,
    };
  },
  data() {
    return {
      slickOptions: {
        dots: false,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow: null,
        nextArrow: null,
        pauseOnHover: false,
        infinite: true,
        slidesToShow: 4,
        initialSlide: 1,
        slidesToScroll: 1,
        vertical: false,
        focusOnSelect: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
              dots: false,
              centerPadding: "50px",
            },
          },
        ],
      },
      mounted: false,
    };
  },
  methods: {
    async refreshLongLivedToken() {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${process.env.VSF_INSTA_FEED}`
        );
      } catch (error) {
        console.error(
          "Error refreshing token:",
          error.response ? error.response.data : error.message
        );
      }
    },
    async getInsta() {
      try {
        await this.refreshLongLivedToken();
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink&access_token=${process.env.VSF_INSTA_FEED}`
        );
        this.instafeed = response.data.data;
      } catch (error) {
        console.error("Error fetching Instagram data:", error);
      }
    },
  },
  mounted() {
    this.getInsta();
  },
};
</script>
<style lang="scss" scoped>
.bg-grey {
  background-color: #efefef;
}
.curve-line {
  position: absolute;
  height: 325px;
  width: 120%;
  margin: auto;
  top: -20%;
  margin-left: -10vw;
  margin-right: -10vw;
  background-color: white;
  clip-path: ellipse(50% 50% at 50% 50%);
  overflow: hidden;
  @media only screen and (max-width: 1023px) {
    height: 150px;
    top: -16%;
  }
  @media only screen and (max-width: 768px) {
    height: 120px;
    top: -12%;
    clip-path: ellipse(50% 50% at 50% 50%);
  }
  @media only screen and (max-width: 481px) {
    clip-path: ellipse(46% 50% at 46% 50%);
  }
}
.text-wrapper {
  @media only screen and (max-width: 1023px) {
    padding: 0;
    margin: 40px 0;
  }
  @media only screen and (max-width: 768px) {
    margin: 0;
    @apply py-6;
    margin-top: 10px;
  }
}
.inspiration-slider {
  @media only screen and (max-width: 1366px) {
    padding: 0 40px 30px 40px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin: 30px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 15px;
  }

  .slider ::v-deep .slick-track {
    display: flex;
    gap: 10px;
    .custom-arrow-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      left: -35px;
    }

    .custom-arrow-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      right: -35px;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-next:before {
    content: url("/icons/icons/chevron-right.svg");
    font-weight: bold;
    line-height: 1;
    opacity: 0.75;
    color: red;
    display: none;
  }
  .slick-prev:before {
    content: url("/icons/icons/chevron-left.svg");
    font-weight: bold;
    line-height: 1;
    opacity: 0.75;
    color: red;
    display: none;
  }
  .slick-next {
    width: auto;
    height: auto;
    right: -50px;
    @media only screen and (max-width: 480px) {
      right: -40px;
    }
  }
  .slick-prev {
    width: auto;
    height: auto;
    left: -50px;
    @media only screen and (max-width: 480px) {
      left: -40px;
    }
  }
  .slick-track {
    display: flex !important;
  }
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide {
    height: auto;
    margin: 0 5px;
    div {
      height: 100%;
    }
    img {
      border-radius: 0.75rem;
      object-fit: contain;
      object-position: center;
    }
  }
}
.inspo-text {
  p {
    text-align: center;
    letter-spacing: 0px;
    color: #261717;
    opacity: 1;
  }
}
.world-of-inspiration {
  @media only screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
    .text-red-title {
      font: normal normal normal 30px/32px Montserrat;
    }
  }
}
@media only screen and (max-width: 480px) {
  .btn {
    font-size: 14px;
    padding: 11px 29px;
  }
}
</style>
