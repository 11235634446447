<template>
  <div>
    <div class="my-20 overflow-hidden desktop">
      <div class="container flex relative">
        <div class="slider slider-trends" :style="{ height: sliderHeight }">
          <ul
            ref="slider1"
            class="slider-list"
            :style="{ transform: `translateY(${offset1}px)` }"
          >
            <li v-for="(blok, index) in blok.blocks[1].blocks" :key="index">
              <BlogItem :blok="blok" :isMobile="false" />
            </li>
          </ul>
        </div>
        <div class="slider" :style="{ height: sliderHeight }">
          <ul
            ref="slider2"
            class="slider-list"
            :style="{ transform: `translateY(${offset2}px)` }"
          >
            <li v-for="(blok, index) in blok.blocks[0].blocks" :key="index">
              <BlogItem :blok="blok" :isMobile="false" />
            </li>
          </ul>
        </div>
        <div class="controls pb-4 hover:cursor-pointer">
          <div @click="scroll(-1)">
            <Icon
              class="icon up"
              icon="charm:chevron-up"
              width="60"
              height="60"
            />
          </div>
          <div @click="scroll(1)">
            <Icon
              class="icon up"
              icon="charm:chevron-down"
              width="60"
              height="60"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mobile px-4">
      <div class="pt-6">
        <div class="custom-controls">
          <button @click="prevSlide">
            <Icon
              class="icon mr-2"
              icon="charm:chevron-left"
              width="40"
              height="40"
            />
          </button>
          <button @click="nextSlide">
            <Icon
              class="icon"
              icon="charm:chevron-right"
              width="40"
              height="40"
            />
          </button>
        </div>
        <client-only>
          <vue-tiny-slider v-bind="tinysettingsbrands" ref="slidermobile">
            <div
              v-for="(blok, index) in blok.blocks[1].blocks"
              :key="index.id"
              class="brands-list"
            >
              <BlogItem :blok="blok" :isMobile="true" />
            </div>
          </vue-tiny-slider>
        </client-only>
      </div>
      <div class="slider-border pt-6">
        <div class="custom-controls">
          <button @click="prevSlide1">
            <Icon
              class="icon mr-2"
              icon="charm:chevron-left"
              width="40"
              height="40"
            />
          </button>
          <button @click="nextSlide1">
            <Icon
              class="icon"
              icon="charm:chevron-right"
              width="40"
              height="40"
            />
          </button>
        </div>
        <client-only>
          <vue-tiny-slider v-bind="tinysettingsinspiration" ref="slider1mobile">
            <div
              v-for="(blok, index) in blok.blocks[0].blocks"
              :key="index.id"
              class="inspiration-list"
            >
              <BlogItem :blok="blok" :isMobile="true" />
            </div>
          </vue-tiny-slider>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script>
import BlogItem from "./BlogItem.vue";
import { Icon } from "@iconify/vue2";

export default {
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
  components: {
    BlogItem,
    Icon,
  },
  data() {
    return {
      offset1: 0,
      offset2: 0,
      sliderHeight: "1000px", // Set your desired maximum height here
    };
  },
  methods: {
    scroll(direction) {
      const slider1 = this.$refs.slider1;
      const slider2 = this.$refs.slider2;
      const parentContainerHeight = 1000; // Adjust this to your maximum height

      const itemHeight1 = this.itemHeight(1);
      const itemHeight2 = this.itemHeight(2);

      const maxOffset1 = -itemHeight1 * (slider1.children.length - 2);
      const maxOffset2 = -itemHeight2 * (slider2.children.length - 1);

      let newOffset1 = this.offset1;
      let newOffset2 = this.offset2;

      if (direction === -1) {
        if (newOffset1 > maxOffset1) {
          newOffset1 = Math.max(this.offset1 - itemHeight1, maxOffset1);
        }

        if (newOffset2 > maxOffset2) {
          newOffset2 = Math.max(this.offset2 - itemHeight2, maxOffset2);
        }

        // Check if we reached the end from the bottom when scrolling up
        const reachedEndFromBottom1 = newOffset1 === maxOffset1;
        const reachedEndFromBottom2 = newOffset2 === maxOffset2;

        // Fix the sliders to the parent bottom when reaching the end from the bottom
        if (reachedEndFromBottom1 && this.offset1 !== maxOffset1) {
          newOffset1 = parentContainerHeight - slider1.scrollHeight + 10; // Add 10px
        }

        if (reachedEndFromBottom2 && this.offset2 !== maxOffset2) {
          newOffset2 = parentContainerHeight - slider2.scrollHeight + 10; // Add 10px
        }
      } else if (direction === 1) {
        if (this.offset1 < 0) {
          newOffset1 = Math.min(this.offset1 + itemHeight1, 0);
        }

        if (this.offset2 < 0) {
          newOffset2 = Math.min(this.offset2 + itemHeight2, 0);
        }

        // Check if we reached the top when scrolling down
        const reachedTop1 = newOffset1 === 0 && this.offset1 < 0;
        const reachedTop2 = newOffset2 === 0 && this.offset2 < 0;

        // Reset the sliders to the top when reaching the top
        if (reachedTop1) {
          newOffset1 = 0;
        }

        if (reachedTop2) {
          newOffset2 = 0;
        }
      }

      // Check if either slider has reached the end
      const reachedEnd1 = newOffset1 === maxOffset1;
      const reachedEnd2 = newOffset2 === maxOffset2;

      // Update offsets only if both sliders have not reached the end
      if (!(reachedEnd1 && reachedEnd2)) {
        this.offset1 = newOffset1;
        this.offset2 = newOffset2;
      }
    },
    itemHeight(sliderIndex) {
      const slider =
        sliderIndex === 1 ? this.$refs.slider1 : this.$refs.slider2;
      const item = slider ? slider.querySelector("div") : null;
      return item ? item.offsetHeight : 0;
    },
    prevSlide1() {
      this.$refs.slider1mobile.slider.goTo("prev");
    },
    nextSlide1() {
      this.$refs.slider1mobile.slider.goTo("next");
    },
    prevSlide() {
      this.$refs.slidermobile.slider.goTo("prev");
    },
    nextSlide() {
      this.$refs.slidermobile.slider.goTo("next");
    },
  },
  /* mounted() {
    this.offset1 = -this.itemHeight(1) - 30;
    this.offset2 = -this.itemHeight(2) - 30;
  }, */
  setup(props) {
    const tinysettingsbrands = {
      container: "#mouse-drag",
      mouseDrag: true,
      loop: false,
      nav: false,
      controls: true,
      autoHeight: false,
      "nav-container": false,
      items: 1,
      slideBy: "page",
      swipeAngle: false,
      speed: 600,
      edgePadding: 0,
      gutter: 0,
    };
    const tinysettingsinspiration = {
      container: "#mouse-drag",
      mouseDrag: true,
      loop: false,
      controls: true,
      nav: false,
      autoHeight: false,
      "nav-container": false,
      items: 1,
      slideBy: "page",
      swipeAngle: false,
      speed: 400,
      edgePadding: 0,
      gutter: 0,
    };
    return {
      tinysettingsbrands,
      tinysettingsinspiration,
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
}

.slider {
  width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.slider ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: transform 0.3s ease-in-out;
}
.controls {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  background-color: white;
  .icon {
    border-radius: 50%;
    border: 1px solid #cd1619;
    width: 50px;
    height: 50px;
    @media only screen and (max-width: 1023px) {
      width: 40px;
      height: 40px;
    }
  }
  .up {
    color: #cd1619;
    font-weight: bold;
  }
  .down {
    color: #cd1619;
    font-weight: bold;
  }
}
.controls button {
  margin: 0 5px;
}
.slider-trends {
  border-right: 1px solid #707070;
  .blog-item {
    border-top: none;
    border-bottom: 1px solid #707070;
    padding-left: 0;
    /* margin-left: 5rem; */
    margin-left: 50px;
    @media only screen and (max-width: 1280px) {
      margin-left: 30px;
    }
    @media only screen and (max-width: 1023px) {
      margin-left: 20px;
    }
  }
}
.mobile {
  display: none;
  .slider-border {
    border-top: 1px solid #707070;
  }
}
@media only screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
.icon {
  color: #cd1619;
  border: 1px solid #cd1619;
  border-radius: 50%;
}
::v-deep .tns-controls {
  display: none;
}
.custom-controls {
  @apply px-6;
}
</style>
