<template>
  <div class="blog-item p-6 lg:p-8 lg:px-20">
    <nuxt-link
      v-if="blok.link"
      :to="
        localePath(
          `/${
            blok.link.cached_url.startsWith('/')
              ? blok.link.cached_url.substring(4)
              : blok.link.cached_url
          }`
        )
      "
    >
      <h3 v-if="blok.title" class="title font-bold mb-6">
        {{ blok.title }}
      </h3>
      <div v-if="blok.img_below_text == true">
        <p v-if="blok.text" class="blog-text font-fgb mb-6 leading-loose">
          {{ lessText }}
          <nuxt-link
            v-if="seeMore == true"
            :to="
              localePath(
                `/${
                  blok.link.cached_url.startsWith('/')
                    ? blok.link.cached_url.substring(4)
                    : blok.link.cached_url
                }`
              )
            "
          >
            <span class="read-more font-fgb">read more</span>
          </nuxt-link>
        </p>
        <div v-if="blok.richtext">
          <div
            v-html="$storyapi.richTextResolver.render(blok.richtext)"
            class="text-2xl font-fgb"
          />
        </div>
        <div v-if="blok.img.filename" class="mb-6">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.img.filename + '/m/filters:format(webp)'"
            class="rounded-xl"
            alt="blog image"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="blok.img.filename" class="mb-6">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.img.filename + '/m/filters:format(webp)'"
            class="rounded-xl"
            alt="blog image"
          />
        </div>
        <p v-if="blok.text" class="blog-text font-fgb mb-6 leading-loose">
          {{ lessText }}

          <span class="read-more font-fgb">read more</span>
        </p>
        <div v-if="blok.richtext">
          <div
            v-html="$storyapi.richTextResolver.render(blok.richtext)"
            class="text-2xl font-fgb"
          />
        </div>
      </div>
    </nuxt-link>
    <div v-else>
      <h3 v-if="blok.title" class="title font-bold mb-6">
        {{ blok.title }}
      </h3>
      <div v-if="blok.img_below_text == true">
        <p v-if="blok.text" class="blog-text font-fgb mb-6 leading-loose">
          {{ lessText }}
          <nuxt-link v-if="seeMore == true" to="/">
            <span class="read-more font-fgb">read more</span>
          </nuxt-link>
        </p>
        <div v-if="blok.richtext">
          <div
            v-html="$storyapi.richTextResolver.render(blok.richtext)"
            class="text-2xl font-fgb"
          />
        </div>
        <div v-if="blok.img.filename" class="mb-6">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.img.filename + '/m/filters:format(webp)'"
            class="rounded-xl"
            alt="blog image"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="blok.img.filename" class="mb-6">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.img.filename + '/m/filters:format(webp)'"
            class="rounded-xl"
            alt="blog image"
          />
        </div>
        <p v-if="blok.text" class="blog-text font-suwa mb-6 leading-loose">
          {{ lessText }}
          <nuxt-link v-if="seeMore == true" to="/">
            <span class="read-more font-fgb">read more</span>
          </nuxt-link>
        </p>
        <div v-if="blok.richtext">
          <div
            v-html="$storyapi.richTextResolver.render(blok.richtext)"
            class="text-2xl font-fgb"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "@nuxtjs/composition-api";
export default {
  name: "BlogItem",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const seeMore = ref(false);

    const lessText = ref("");

    onMounted(() => {
      if (props.blok.img.filename != "" && props.isMobile == true) {
        const textElement = props.blok.text.substring(0, 250) + "...";
        lessText.value = textElement;
        seeMore.value = true;
      } else if (props.isMobile == true) {
        lessText.value = props.blok.text;
        seeMore.value = true;
      } else {
        lessText.value = props.blok.text;
      }
    });

    return {
      seeMore,
      lessText,
    };
  },
};
</script>
<style scoped lang="scss">
.blog-item {
  border-bottom: 1px solid #707070;
  .title {
    font: normal normal bold 41px/43px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    @media only screen and (max-width: 480px) {
      font: normal normal bold 30px/32px Montserrat;
    }
  }
  @media only screen and (max-width: 480px) {
    border: none;
    max-height: 600px;
  }
}
.blog-text {
  text-align: left;
  letter-spacing: 0px;
  color: #261717;
  opacity: 1;
  @media only screen and (max-width: 480px) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 14;
  }
}
.read-more {
  color: #cd1719;
}
</style>
